import React from 'react';
import styles from '../styles/electronic-store.module.css';

export const Loader: React.FC = () => {
  return (
    <div className={styles.loaderContainer}>
      <div className={styles.loader}>
        <div className={styles.loaderLogo}>PixelTrek</div>
        <div className={styles.loaderSpinner}></div>
      </div>
    </div>
  );
};